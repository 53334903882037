<script setup>
import { ref } from 'vue';

// Components
import Icon from '@/Components/Icon.vue';

// Icons
import minus from '@i/minus-orange.svg';
import plus from '@i/plus-pink.svg';

const props = defineProps({
    title: String,
    faqs: Object,
});

const faq = ref(props.faqs);

const current = ref(faq.value[0]?.id);
</script>

<template>
    <div class="my-5">
        <ul>
            <li
                class="px-6 py-4 mb-4 rounded-lg cursor-pointer bg-blue-medium"
                v-for="q in faqs"
                :key="q.id"
                v-auto-animate
            >
                <div class="gap-4" @click="current = current === q.id ? false : q.id">
                    <div class="flex items-center justify-between text-sm uppercase md:text-xl">
                        <div>
                            {{ q.question }}
                        </div>

                        <Icon :icon="minus" class="w-4 h-4" v-if="q.id === current" />
                        <Icon :icon="plus" class="w-4 h-4" v-else />
                    </div>
                </div>

                <p class="mt-6 text-xs text-white answer md:text-base whitespace-pre-line" v-if="q.id === current" v-html="q.answer" />
            </li>
        </ul>
    </div>
</template>
